@keyframes slideInLeft {
  from {
    opacity: 0;
    translate: -100px 0px;
  }
  to {
    opacity: 1;
    translate: 0px 0px;
  }
}

.slide-in-left {
  animation: slideInLeft 0.75s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

@keyframes slideInRight {
  from {
    opacity: 0;
    translate: 100px 0px;
  }
  to {
    opacity: 1;
    translate: 0px 0px;
  }
}

.slide-in-right {
  animation: slideInRight 0.75s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

@keyframes slideInBottom {
  from {
    opacity: 0;
    translate: 0px 100px;
  }
  to {
    opacity: 1;
    translate: 0px 0px;
  }
}

.slide-in-bottom {
  animation: slideInBottom 0.75s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

.zoom-in-light {
  transition: transform 0.75s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.zoom-in-light:hover {
  transform: scale(1.05);
  transition: all 0.75s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.zoom-in-hard {
  transition: transform 0.75s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.zoom-in-hard:hover {
  transform: scale(1.1);
  transition: all 0.75s cubic-bezier(0.25, 0.1, 0.25, 1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.opacity-effect {
  opacity: 0;
  animation: fadeIn 0.75s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

.opacity-delay-effect-1 {
  opacity: 0;
  animation: fadeIn 0.75s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  animation-delay: 0.75s;
}

.opacity-delay-effect-2 {
  opacity: 0;
  animation: fadeIn 0.75s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  animation-delay: 1s;
}

.opacity-delay-effect-3 {
  opacity: 0;
  animation: fadeIn 0.75s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  animation-delay: 1.25s;
}

.opacity-delay-effect-4 {
  opacity: 0;
  animation: fadeIn 0.75s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  animation-delay: 1.5s;
}

.opacity-delay-effect-5 {
  opacity: 0;
  animation: fadeIn 0.75s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  animation-delay: 1.75s;
}

.rotation {
  opacity: 0;
  transform: rotate(-360deg);
  transition: opacity 1s, transform 1s;

  &.visible {
    opacity: 1;
    transform: rotate(0deg);
  }
}
