.epworth {
  max-width: 800px; // Limiter la largeur pour un meilleur contrôle du layout
  margin: 0px auto 40px auto; // Centrer le formulaire sur la page
  padding: 20px;
  background: #f9f9f9; // Couleur de fond légère pour le formulaire
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Ombre subtile pour le formulaire

  h1 {
    text-align: center; // Titre centré
    margin-bottom: 30px; // Espace sous le titre
  }

  div.question {
    display: grid; // Utilisation de CSS Grid
    grid-template-columns: 2fr 1fr; // Deux colonnes: une large pour la question, une étroite pour les boutons
    gap: 10px; // Espace entre les éléments de grille
    padding-top: 10px; // Espace entre les questions
    margin-bottom: 15px; // Espace entre les questions
    align-items: center;

    p {
      margin: 0; // Enlever la marge par défaut des paragraphes
      align-self: center; // Aligner le texte verticalement au centre
      text-align: left;
    }

    label {
      display: inline-block;
      margin-right: 10px; // Espace entre les boutons radio

      input {
        margin-right: 5px; // Espace entre le bouton radio et son label
      }
    }
  }

  button {
    display: block; // Assure que le bouton est un bloc
    // width: 100%; // Le bouton prend toute la largeur disponible
    width: auto; // Le bouton prend toute la largeur disponible
    padding: 10px; // Padding pour agrandir le bouton
    background-color: #f8b5a8; // Couleur de fond bleue
    color: white; // Texte blanc
    border: none; // Pas de bordure
    border-radius: 5px; // Bordures arrondies
    cursor: pointer; // Curseur en forme de pointeur pour indiquer la possibilité de cliquer
    transition: background-color 0.3s; // Transition pour l'effet au survol
    margin: 20px auto;

    &:hover {
      background-color: #f28c78; // Assombrir le bouton au survol
    }
  }

  p.result {
    font-weight: bold; // Texte du résultat en gras
    text-align: center; // Résultat centré
    margin-top: 20px; // Espace au-dessus du résultat
  }

  //   .p-stepper .p-stepper-header .p-stepper-action {
  //     background: none;
  //   }
}

// button.p-stepper-action {
//   background: none !important;
// }
ul.p-stepper-nav {
  display: none !important;
}

.depist-dial {
  margin: auto 5vw;
}

.p-stepper-panels {
  background: none !important;
}

div.p-button .p-component {
  border-radius: 50% !important;
}

.select-b-round .p-button {
  border-radius: 50% !important;
  margin-right: 10px;
}
