#diapo-sect {
  padding: 5vh 0px;
  // background-color: #e8e1d9;
  background-color: none;
  max-height: calc(100vh - 40px);

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 101px;
  a {
    margin: 0 auto;
  }

  #lisa-birds {
    height: auto;
    // width: 220px;
    // max-width: 20vw;
    // min-width: 130px;
    max-width: 220px;
    width: 20vw;
    min-width: 130px;
    // margin-top: auto;
    margin-top: 20px;
    margin-bottom: auto;

    margin-left: auto;
    margin-right: auto;
  }
}
