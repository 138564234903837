@media screen and (max-width: 715px) {
  .App {
    // min-height: 100vh;
  }
  #diapo-sect {
    padding: 15px 30px 30px 30px;

    #lisa-birds {
      width: 30vw;
    }
  }

  #homePage {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    #action-sect {
      height: auto;
      .action-blocs {
        max-height: 25vh;
        margin: 1vh 2vh 2vh 2vh;

        h2 {
          font-size: large;
          font-weight: bolder;
          margin-bottom: 5px;
        }
        p {
          font-size: medium;
        }
        img {
          max-height: 10vh;
        }
      }
      .action-blocs:last-child {
        margin-bottom: 6vh;
      }
    }
  }

  #teamPage {
    #team-sect {
      margin: 2vh 10vw 5vh 10vw;

      h1 {
        font-size: 2rem;
      }

      div {
        font-size: 18px;
      }
    }
  }

  #footer-blc {
    margin-top: auto;
    padding-bottom: 0px;
    // div:nth-child(1) {
    //   order: 2;
    // }
    // div:nth-child(2) {
    //   order: 1;
    // }
    // div:nth-child(3) {
    //   order: 3;
    // }
    // div:last-child {
    //   margin-top: 30px;
    //   font-size: smaller;
    // }
    // div {
    //   margin-bottom: 20px;
    // }
  }

  .lcxmeuseBloc {
    .lcxmeuseImg {
      width: auto !important;
      max-width: 80vw !important;
    }
  }
}
