#action-sect {
  height: 100vh;
  // background-color: #e8e1d9;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-evenly;
  align-items: stretch;

  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
  a:hover {
    // text-decoration: underline;
    text-decoration: none;
  }
  a:active {
    // text-decoration: underline;
    text-decoration: none;
  }

  .action-blocs {
    color: black;
    width: 40vw;
    min-width: 335px;
    height: 40vh;
    background-color: rgba(255, 0, 0, 0.111);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    border-radius: 47px;
    cursor: pointer;

    h2 {
      font-family: "NaiveMedium";
      text-decoration: none;
      margin: 10px 0 0 0;
    }
    p {
      font-size: larger;
      margin: 0 0 10px 0;
    }

    img {
      width: auto;

      max-height: calc(100% - 10vw);
      height: auto;
    }
  }

  // .action-blocs:only-child {
  // }
}
