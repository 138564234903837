#teamPage {
  background-color: #e8e1d9;
  div {
    text-align: left;
  }

  span {
    height: max-content;
  }

  #team-sect {
    margin: 2vh 25vw 5vh 25vw;
    height: -webkit-fill-available;
    font-size: larger;

    div {
      margin-bottom: 10vh;
      font-size: x-large;
    }
  }

  a:link {
    color: black;
  }
  a:visited {
    color: black;
  }
  a:hover {
    color: black;
  }
  a:active {
    color: black;
  }
}
