#us-blc {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 20px 0px 50px 0px;
  div {
    height: 300px;
    width: 300px;
    // background-color: #cedad7;
    // background-color: #f1e48c;
    background-color: #c7ddd5;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;

    cursor: pointer;

    span {
      font-size: 22px;
      font-weight: bold;
      //   font-family: "NaiveMedium";
      font-style: italic;
    }
  }
}
