#footer-blc {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  // background-color: #99c8be;
  background-color: #e3d9fb;
  padding: 30px 30px 20px 30px;

  div:last-child {
    margin-top: 30px;
    font-size: smaller;
    width: 100%;
    text-align: center;
  }

  div,
  span {
    text-align: left;
  }
  h3 {
    margin: 5px 0;
  }

  #Link-2 {
    font-size: x-large;
  }

  a {
    color: #000;
    cursor: pointer;
    font-size: 14px;
  }

  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  a:active {
    text-decoration: underline;
  }
}
